import store from "@/store";

/**
 * requestInterceptor
 * @description Intercepts all request to Connector app
 */
export const requestInterceptor = (req) => {
  const token = store.getters["auth/cookieToken"]?.token ?? "";
  // Adds MP-ID-TOKEN header in the request
  req.headers["MP-ID-TOKEN"] = token;

  return req;
};
