/*eslint-disable */
import axios from "axios";
import { responseInterceptor } from "@/services/apis/http/interceptors";
import { requestInterceptor } from "@/services/apis/connector/connector.interceptors";
import apiConfig from "@/config/api.config";

/**
 * $connector
 * BaseUrl of this instance will be dynamic in nature
 */
const $connector = axios.create({
  baseURL: apiConfig.CONNECTOR_APP_BASE_URL,
  withCredentials: true,
});

/**
 * Intercepts all requests before sending it to the server
 */
$connector.interceptors.request.use(requestInterceptor);

/**
 * Intercepts all api responses and error handling
 */
$connector.interceptors.response.use(null, responseInterceptor);

export default $connector;
