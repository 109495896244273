import $connector from "./apis/connector";

/**
 * handleConnectorApp
 * @description Handles connector app requests
 * @returns {Promise}
 */
export const handleConnectorApp = (params = {}) => {
  return $connector.get("connection/shopify/callback", { params });
};
