<template>
  <div id="integrations-account-selector">
    <auth-forms-wrapper
      show-links
      width="392px"
      card-classes="px-0"
      wrapper-classes="py-8 px-6"
    >
      <template #form>
        <v-form
          v-model="isFormValid"
          @submit.prevent="onSubmitHandler"
          id="integrations-account-selector-form"
        >
          <v-row>
            <v-col class="pb-0">
              <h3
                v-text="'You Are Almost Done'"
                class="secondary--font text--primary pb-2"
              />
              <p
                v-text="'Select the account you want to access'"
                class="body-2 font-regular secondary--font text--primary pb-1"
              />

              <account-selector
                return-object
                :rules="rules"
                @changed="onAccountChange"
              />
              <alert-message v-show="!!errorMessage" :message="errorMessage" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                block
                x-large
                type="submit"
                color="dark-black"
                :loading="isLoading"
                :disabled="!isFormValid"
                class="font-weight-bold white--text"
                v-track="'integrations-account-selector-submit-btn'"
              >
                DONE
              </v-btn>

              <v-btn
                text
                @click.prevent="toDashboard"
                color="dark-black"
                :disabled="isLoading"
                class="mt-3 white--text"
                v-track="'integrations-account-selector-back-to-profile-btn'"
              >
                Back To Profile
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
/*eslint-disable */

import ConnectorApp from "@/classes/ConnectorApp";
import { required } from "@/validators/form-validators.js";
import AccountSelector from "@/components/shared/AccountSelector.vue";

import { mapActions, mapMutations } from "vuex";
import { ERROR_MESSAGES } from "@/constants/app";
import { isEmpty, queryStringToObject, sanitaiseInvalidRoute } from "@/utils";

import AlertMessage from "@/components/shared/AlertMessage.vue";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

/**
 * Manages Intergations Accounts Selection
 * @author Jatin Kamboj
 */
export default {
  name: "IntegrationsAccountSelector",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AccountSelector, AuthFormsWrapper, AlertMessage },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      selectedAccount: null,
      errorMessage: "",
      connectorConfig: new ConnectorApp(),
      rules: [required("Account Name")],
    };
  },
  /**
  |--------------------------------------------------
  | Computed Properties
  |--------------------------------------------------
  */
  computed: {
    queryString() {
      const url = new URL(window.location.href);
      let queryParams = this.connectorConfig.getRouteConfig();

      if (!isEmpty(queryParams)) return queryParams;
      else if (url.search?.length > 0) return queryStringToObject(url.search);
    },
    payload() {
      return {
        ...this.queryString,
        account_id: this.selectedAccount?.id,
      };
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setHideSnackbar: "ui/setHideSnackbar",
      setConnectorAppParams: "ui/setConnectorAppParams",
    }),
    onAccountChange(value) {
      this.selectedAccount = value;
    },
    setErrorMessage(error) {
      this.errorMessage = error;
    },
    async toDashboard() {
      await this.setConnectorAppParams(null);
      this.$router.replace("/dashboard");
    },
    /**
     * Handles form submittion
     */
    async onSubmitHandler() {
      try {
        this.setHideSnackbar(true);
        this.setErrorMessage("");
        this.isLoading = true;

        await this.connectorConfig.handleRequest(this.payload);
      } catch (error) {
        this.setErrorMessage(ERROR_MESSAGES.defaultTryLater);
      } finally {
        this.isLoading = false;
        this.setHideSnackbar(false);
      }
    },
    sanitiseCurrentRoute() {
      sanitaiseInvalidRoute();
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.sanitiseCurrentRoute();
  },
};
</script>
